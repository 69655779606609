<template>
  <div>
    <b-container>
      <b-row>
        <b-col
          class="my-1"
          v-for="(post, index) in visibleCards"
          :key="index"
          cols="12"
          md="6"
          lg="4"
          :style="{ display: post.opacity, visibility: post.visibility }"
          @click="goToPostDetail(post.post_id)"
        >
          <!-- xl="3" -->

          <b-card
            :img-src="post.table_image"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2 card-hover card-image"
          >
            <!-- :class="{ 'card-custom': cards.length === 1 }" -->
            <p class="card-text text-muted mb-2">{{ post.created_at }}</p>

            <h4 style="font-size: 16px; font-weight: bold">{{ post.title }}</h4>

            <div class="d-flex justify-content-between">
              <span>{{ post.user.username }}</span>

              <div class="interaction">
                <!-- <span>조회수: {{ post.views ? post.views : 0 }} </span> -->
                <span
                  ><img :src="imageSrcEyeIcon" alt="eye-icon-image" />
                  {{ post.views ? post.views : 0 }}
                </span>
                <!-- <span>댓글: {{ post.comments ? post.comments : 0 }} </span> -->
                <span
                  ><img :src="imageSrcCommentIcon" alt="comment-icon-image" />
                  {{ post.comments ? post.comments : 0 }}
                </span>
              </div>
            </div>
          </b-card>

          <!-- <b-card
            title="Card Title"
            :img-src="tableImage"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";
import { dummyCardImage } from "@/assets/dummyCardImage";

import eyeIcon from "@/assets/icon/home/eye-icon.png";
import commentIcon from "@/assets/icon/home/comment-icon.png";

export default {
  props: {
    selectedMenu: String,
  },

  data() {
    return {
      cards: [],

      invisibleCard: {
        post_id: 54,
        title: "제목",
        content: "내용",
        lines: [
          {
            points: [329.5, 552.265625, 335.5, 711.265625],
            stroke: "white",
            strokeWidth: 1.5,
          },
        ],
        linesVertical: null,
        ballConfig: [
          {
            x: 428,
            y: 317.5,
            radius: 13,
            draggable: true,
          },
        ],
        ballConfigVertical: null,
        table_image: dummyCardImage.tableImage,
        created_at: "2024-11-05T02:16:40.946Z",
        updated_at: "2024-11-05T02:16:40.946Z",
        opacity: 0,
        visibility: "hidden",
        user: {
          username: "qplay",
        },
      },

      imageSrcEyeIcon: eyeIcon,
      imageSrcCommentIcon: commentIcon,
    };
  },

  created() {
    this.fetchData();
  },

  watch: {
    selectedMenu(newVal) {
      this.fetchData(newVal);
    },
  },

  methods: {
    async fetchData(menu = "all") {
      let response;

      try {
        if (menu == "all") {
          response = await axiosInstance.get("/posts");
        }
        if (menu == "personal") {
          response = await axiosInstance.get("/posts/user");
        }

        this.cards = response.data.posts;

        this.cards = this.cards.map((item) => {
          return {
            ...item,
            created_at: item.created_at.split("T")[0],
          };
        });

        // console.log("HOME", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // console.log("Unauthorized");
        }
        // 개발
        // console.error("Error fetching data:", error);
      }
    },

    goToPostDetail(postId) {
      this.$router.push({ name: "post-detail", params: { id: postId } });
    },
  },

  computed: {
    visibleCards() {
      const additionalCardsCount =
        this.cards.length === 1 ? 2 : this.cards.length === 2 ? 1 : 0;
      const additionalCards = Array(additionalCardsCount).fill(
        this.invisibleCard
      );

      return [...this.cards, ...additionalCards];
    },
  },
};
</script>

<style scoped>
.card-hover {
  transition: transform 0.3s ease;
  cursor: pointer;
}
.card-hover:hover {
  transform: translateY(-10px);
}

.interaction span:first-child {
  margin-right: 18px;
}

.interaction span:first-child img {
  margin-right: 3px;
}
/* .interaction span:last-child img {
  margin-right: 2px;
} */

.interaction span img {
  width: 20px;
  vertical-align: sub;
}

/* .card-custom {
  min-width: 414px;
} */
</style>
