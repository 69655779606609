<template>
  <div>
    <!-- Header -->
    <header>
      <b-navbar
        toggleable="lg"
        type="light"
        class="shadow-sm mb-3"
        style="height: 80px"
      >
        <b-container class="px-3">
          <b-row class="align-items-end">
            <b-col cols="auto">
              <b-navbar-brand @click="goToHome" style="cursor: pointer"
                ><img
                  class="qplay-logo"
                  src="@/assets/logo/logo.svg"
                  alt="Logo"
              /></b-navbar-brand>
            </b-col>

            <b-col class="d-flex align-items-center" id="nav">
              <ul
                class="navbar-nav d-flex flex-row align-items-center"
                style="gap: 5px"
              >
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/"
                    @click="selectMenu('all')"
                    >전체</router-link
                  >
                </li>
                <li class="nav-item">
                  <span class="nav-link">|</span>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/post/user"
                    @click="selectMenu('personal')"
                    >개인</router-link
                  >
                </li>
                <!-- <li class="nav-item">
                  <span class="nav-link">|</span>
                </li> -->
                <!-- <li class="nav-item">
                  <router-link class="nav-link" to="/post/create"
                    >등록</router-link
                  >
                </li> -->
                <li class="nav-item" style="margin-left: 8px">
                  <router-link class="nav-link" to="/post/create"
                    ><img
                      :src="imageSrcPlusIcon"
                      alt="plus-circle-image"
                      style="width: 20px; vertical-align: text-bottom"
                  /></router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link class="nav-link" to="/post/create"></router-link>
                </li> -->
              </ul>

              <ul class="navbar-nav ms-auto d-flex flex-row" style="gap: 10px">
                <li class="nav-item" v-if="!isAuthenticated">
                  <router-link class="nav-link" to="/login">로그인</router-link>
                </li>
                <li class="nav-item" v-if="isAuthenticated">
                  <a class="nav-link" style="font-weight: bold">{{
                    username
                  }}</a>
                </li>
                <li class="nav-item" v-if="isAuthenticated">
                  <a class="nav-link" @click="logOut()"
                    ><img
                      :src="imageSrcSignOutIcon"
                      alt="plus-circle-image"
                      style="width: 20px; vertical-align: text-bottom"
                  /></a>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </b-navbar>
    </header>

    <!-- Main -->
    <main style="margin-bottom: 100px">
      <!-- <router-view :key="shouldUseKey ? $route.path : null" /> -->
      <router-view :selectedMenu="selectedMenu" />
    </main>

    <!-- Footer -->
    <hr class="container" style="opacity: 0.1" />
    <footer class="text-black pb-4">
      <div class="container text-center">
        <!-- 로고 -->
        <div @click="goToHome">
          <h4>
            <img
              class="qplay-logo"
              src="@/assets/logo/logo.svg"
              alt="Footer_Logo"
            />
          </h4>
        </div>

        <!-- 연락처 및 저작권 정보 -->
        <div class="footer-info">
          <p>&copy; 2024 <strong>Qplay</strong> All rights reserved</p>
          <!-- <span>|</span> -->
          <p>Contact: qplay@gmail.com</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";

import plusIcon from "@/assets/icon/defaultLayout/plus-icon.png";
import signOutIcon from "@/assets/icon/defaultLayout/signout-icon.png";

import axiosInstance from "@/api/axiosInstance";

export default {
  components: {
    // Header,
    // Footer,
  },

  data() {
    return {
      isAuthenticated: false,
      username: "",
      selectedMenu: "",

      imageSrcPlusIcon: plusIcon,
      imageSrcSignOutIcon: signOutIcon,
    };
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },

    // shouldUseKey() {
    //   // 특정 경로에서만 key를 사용하도록 설정
    //   return this.$route.path === "/post/user";
    // },
  },

  watch: {
    user(newValue) {
      if (newValue == "logout") {
        this.isAuthenticated = false;
      } else {
        this.username = newValue;
        this.isAuthenticated = true;
      }
    },
  },

  created() {
    this.checkAuth();
  },

  methods: {
    async checkAuth() {
      try {
        const response = await axiosInstance.get("/posts"); // 인증 상태를 확인하는 API
        this.username = response.data.user ? response.data.user.username : null;
        this.isAuthenticated =
          response.status == 200 && this.username ? true : false;
        // 백단에서 @UseGuards(AuthGuard())를 해당 핸들러에 설정하지 않아 response.data.user가 없dj 아래 코드 주석처리

        // console.log(response);
      } catch (error) {
        console.error("인증 상태 확인 실패:", error);
      }
    },

    logOut() {
      localStorage.removeItem("access_token");
      this.isAuthenticated = false;
      this.username = "";
      this.$router.push("/login");
    },

    goToHome() {
      // this.$router.push("/");

      this.$router.push("/").then(() => {
        window.location.reload();
      });
    },

    async selectMenu(menu = "all") {
      this.selectedMenu = menu;
    },
  },
};
</script>

<style>
b-navbar {
  max-width: 960px;
  margin: 0 auto;
}

main {
  display: flex;
  justify-content: center;
}

.padding-bottom-0 a {
  padding-bottom: 0 !important;
}

.padding-0 a {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#nav li,
#nav a,
#nav span {
  margin: 0;
  padding: 0;
}

#nav li:nth-child(2) {
  cursor: pointer;
}

.navbar-brand {
  padding-bottom: 0 !important;
}

.qplay-logo {
  width: 100px;
  cursor: pointer;
}

/* footer {
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
} */

footer h4 {
  margin-left: 15px;
  margin-right: 10px;
}

footer > div {
  align-items: center !important;
}

.footer-info {
  align-items: center !important;
  gap: 10px;
}

.footer-info p {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #6c757d;
}
</style>
