import { createApp } from "vue";
import App from "@/layouts/DefaultLayout.vue";

import router from "./router";
import store from "./store/index";

import { BootstrapVue3 } from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import VueKonva from "vue-konva";

createApp(App)
  .use(router)
  .use(store)
  .use(BootstrapVue3)
  .use(VueKonva)
  .mount("#app");
