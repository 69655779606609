<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <b-card style="max-width: 480px">
        <h2 class="text-center">로그인</h2>
        <form @submit.prevent="onSubmit">
          <b-form-group label="아이디" label-for="username">
            <b-form-input
              id="username"
              v-model="form.username"
              type="text"
              required
              placeholder="아이디를 입력하세요"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="비밀번호" label-for="password">
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              required
              placeholder="비밀번호를 입력하세요"
            ></b-form-input>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button variant="link" @click="goToRegister">회원가입</b-button>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <b-button type="submit" variant="primary" block>로그인</b-button>
          </div>

          <div
            v-if="error"
            class="alert alert-danger text-center mt-3"
            role="alert"
          >
            {{ error }}
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      error: null,
    };
  },

  methods: {
    async onSubmit() {
      this.error = null;

      try {
        const response = await axiosInstance.post("/users/login", this.form);

        localStorage.setItem("access_token", response.data.access_token);

        // await this.$store.dispatch("login", response.data.username); // dispatch -> actions

        await this.$store.commit("setUser", response.data.username);

        this.$router.push("/");
      } catch (error) {
        if (error.response && error.status == 401) {
          this.error = `${error.response.data.message}`;
        } else {
          this.error = "서버와의 연결에 문제가 발생했습니다.";
        }
      }
    },
    goToRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style scoped></style>
