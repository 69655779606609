<template>
  <div>
    <v-stage
      ref="stage"
      :config="isCompactView ? stageConfigVertical : stageConfig"
      v-show="isImageCaptured"
    >
      <v-layer ref="table">
        <!-- 테이블 외부 프레임 -->
        <v-rect
          :config="isCompactView ? outerFrameConfigVertical : outerFrameConfig"
          fill="#5D4F4F"
        />

        <!-- 테이블 외부 프레임 포인트 -->
        <v-circle
          v-for="dot in isCompactView ? dotsVertical : dots"
          :key="dot.id"
          :config="dot"
          fill="white"
        />

        <!-- 테이블 쿠션 -->
        <v-rect
          :config="isCompactView ? cushionConfigVertical : cushionConfig"
          fill="#3C7AC9"
        />
        <!-- 모바일 터치 이벤트 - @tap -->

        <!-- 테이블 쿠션 눈금 -->
        <v-line
          v-for="line in isCompactView
            ? topCushionLinesVertical
            : topCushionLines"
          :key="line.id"
          :config="line"
          stroke="white"
          :strokeWidth="1"
        />
        <v-line
          v-for="line in isCompactView
            ? bottomCushionLinesVertical
            : bottomCushionLines"
          :key="line.id"
          :config="line"
          stroke="white"
          :strokeWidth="1"
        />
        <v-line
          v-for="line in isCompactView
            ? leftCushionLinesVertical
            : leftCushionLines"
          :key="line.id"
          :config="line"
          stroke="white"
          :strokeWidth="1"
        />
        <v-line
          v-for="line in isCompactView
            ? rightCushionLinesVertical
            : rightCushionLines"
          :key="line.id"
          :config="line"
          stroke="white"
          :strokeWidth="1"
        />

        <!-- 테이블 외부 필드 -->
        <v-rect
          :config="isCompactView ? tableConfigVertical : tableConfig"
          fill="#4A8FEC"
          @click="handleTableClick()"
          @tap="handleTableClick()"
        />
        <!-- 모바일 터치 이벤트 - @tap -->

        <!-- 테이블 내부 필드 -->
        <v-rect
          :config="isCompactView ? tableConfigInnerVertical : tableConfigInner"
          fill="#4A8FEC"
          @click="handleTableClick()"
          @tap="handleTableClick()"
        />

        <!-- 테이블 필드 격자 -->
        <v-line
          v-for="line in isCompactView ? verticalLinesVertical : verticalLines"
          :key="line.id"
          :config="line"
          stroke="white"
          :strokeWidth="0.5"
        />
        <v-line
          v-for="line in isCompactView
            ? horizontalLinesVertical
            : horizontalLines"
          :key="line.id"
          :config="line"
          stroke="white"
          :strokeWidth="0.5"
        />

        <!-- 라인 -->
        <!-- 큐볼, 목표 볼, 상대 볼 태그보다 아래에 있을 경우 공위로 선이 보인다 -->
        <v-line
          v-for="(line, index) in isCompactView ? linesVertical : lines"
          :key="index"
          :config="line"
        />

        <!-- 큐볼 -->
        <v-circle
          :config="isCompactView ? cueBallConfigVertical : cueBallConfig"
          fill="#F2E3A1"
          :shadowBlur="5"
          :strokeWidth="1"
          @dragmove="(e) => handleDragMove('cue', e)"
          @dragend="(e) => handleDragEnd('cue', e)"
          v-if="hiddenCuball"
        />

        <!-- 목표 볼 -->
        <v-circle
          :config="isCompactView ? targetBallConfigVertical : targetBallConfig"
          fill="#F5B401"
          :strokeWidth="1"
          :shadowBlur="5"
          @dragmove="(e) => handleDragMove('target', e)"
          @dragend="(e) => handleDragEnd('target', e)"
          v-if="hiddenTargetBall"
        />

        <!-- 상대 볼 -->
        <v-circle
          :config="
            isCompactView ? opponentBallConfigVertical : opponentBallConfig
          "
          fill="#D30310"
          :strokeWidth="1"
          :shadowBlur="5"
          @dragmove="(e) => handleDragMove('opponent', e)"
          @dragend="(e) => handleDragEnd('opponent', e)"
          v-if="hiddenOpponentBall"
        />

        <!-- 마지막 클릭한 포인트 지점 삼각형 모양 표시 -->
        <v-regular-polygon
          v-if="selectedPoints.length >= 1 && !isCompactView"
          :x="selectedPoints[0].x"
          :y="selectedPoints[0].y"
          :sides="3"
          :radius="7"
          fill="white"
          :rotation="
            previousPoint
              ? calculateRotationAngle(previousPoint, selectedPoints[0])
              : 0
          "
        />

        <v-regular-polygon
          v-if="selectedPotinsVertical.length >= 1 && isCompactView"
          :x="selectedPotinsVertical[0].x"
          :y="selectedPotinsVertical[0].y"
          :sides="3"
          :radius="7"
          fill="white"
          :rotation="
            previousPointVertical
              ? calculateRotationAngle(
                  previousPointVertical,
                  selectedPotinsVertical[0]
                )
              : 0
          "
        />
      </v-layer>

      <v-layer>
        <!-- 토글 큐볼 -->
        <v-circle
          :config="toggleCueBallConfig"
          fill="#F2E3A1"
          :strokeWidth="1"
          @click="handleBallClickVisibility('cue')"
          @tap="handleBallClickVisibility('cue')"
        />
        <!-- 토글 목표볼 -->
        <v-circle
          :config="toggleTargetBallConfig"
          fill="#F5B401"
          :strokeWidth="1"
          @click="handleBallClickVisibility('target')"
          @tap="handleBallClickVisibility('target')"
        />
        <!-- 토글 상대볼 -->
        <v-circle
          :config="toggleOpponentBallConfig"
          fill="#D30310"
          :strokeWidth="1"
          @click="handleBallClickVisibility('opponent')"
          @tap="handleBallClickVisibility('opponent')"
        />

        <!-- 클릭한 포인트 제거 -->
        <!-- <v-circle
:config="removeClickedPointConfig"
fill="#D9D9D9"
strokeWidth="1"
@click="removeClickedPoint()"
/> -->

        <!-- 라인 제거 표시 바(마지막 라인 제거) -->
        <v-line
          :config="redBarConfig1"
          @click="removeLastLineAndClickedPoint()"
          @tap="removeLastLineAndClickedPoint()"
        />
        <v-line
          :config="redBarConfig2"
          @click="removeLastLineAndClickedPoint()"
          @tap="removeLastLineAndClickedPoint()"
        />
      </v-layer>
    </v-stage>

    <div style="padding: 0 55px">
      <!-- <b-form @submit="onSubmit"> -->
      <form @submit.prevent="onSubmit">
        <!-- Title -->
        <b-form-group label="title" label-for="post-title">
          <b-form-input
            id="post-title"
            v-model="form.title"
            placeholder="제목을 입력하세요"
            required
          ></b-form-input>
        </b-form-group>

        <!-- Content -->
        <b-form-group label="content" label-for="post-content">
          <b-form-textarea
            id="post-content"
            v-model="form.content"
            placeholder="내용을 입력하세요"
            rows="6"
            required
          ></b-form-textarea>
        </b-form-group>

        <!-- Submit Button -->
        <b-button type="submit" variant="primary">등록</b-button>
        <!-- <b-button
          type="submit"
          variant="primary"
          @click="saveCanvasAsImage()"
          class="ms-2"
          >수정</b-button
        > -->
      </form>
    </div>

    <!-- Success message -->
    <b-alert v-if="successMessage" show variant="success" class="mt-3">
      {{ successMessage }}
    </b-alert>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      // 게시글 작성
      form: {
        title: "",
        content: "",
        table_image: "",
        lines: [],
        linesVertical: [],
        ballConfig: [],
        ballConfigVertical: [],
      },

      successMessage: "",

      stageConfig: {
        width: 900,
        height: 500,
      },

      stageConfigVertical: {
        // width: 500,
        // height: 900,
        width: 500,
        height: 900,
      },

      // 테이블 외부 프레임 설정
      outerFrameConfig: {
        x: 50,
        y: 50,
        width: 800,
        height: 400,
        cornerRadius: 10,
      },
      outerFrameConfigVertical: {
        x: 50,
        y: 50,
        width: 400,
        height: 800,
        cornerRadius: 10,
      },
      // 테이블 외부 프레임 포인트 설정
      dots: [
        // 위쪽 점들
        { id: "1", x: 800, y: 65, radius: 4 },
        { id: "2", x: 712.5, y: 65, radius: 4 },
        { id: "3", x: 625, y: 65, radius: 4 },
        { id: "4", x: 537.5, y: 65, radius: 4 },
        { id: "5", x: 450, y: 65, radius: 4 },
        { id: "6", x: 362.5, y: 65, radius: 4 },
        { id: "7", x: 275, y: 65, radius: 4 },
        { id: "8", x: 187.5, y: 65, radius: 4 },
        { id: "9", x: 100, y: 65, radius: 4 },
        // 아래쪽 점들
        { id: "10", x: 800, y: 435, radius: 4 },
        { id: "11", x: 712.5, y: 435, radius: 4 },
        { id: "12", x: 625, y: 435, radius: 4 },
        { id: "13", x: 537.5, y: 435, radius: 4 },
        { id: "14", x: 450, y: 435, radius: 4 },
        { id: "15", x: 362.5, y: 435, radius: 4 },
        { id: "16", x: 275, y: 435, radius: 4 },
        { id: "17", x: 187.5, y: 435, radius: 4 },
        { id: "18", x: 100, y: 435, radius: 4 },
        // 우측 점들
        { id: "19", x: 835, y: 100, radius: 4 },
        { id: "20", x: 835, y: 175, radius: 4 },
        { id: "21", x: 835, y: 250, radius: 4 },
        { id: "22", x: 835, y: 325, radius: 4 },
        { id: "23", x: 835, y: 400, radius: 4 },
        // 좌측 점들
        { id: "24", x: 65, y: 100, radius: 4 },
        { id: "25", x: 65, y: 175, radius: 4 },
        { id: "26", x: 65, y: 250, radius: 4 },
        { id: "27", x: 65, y: 325, radius: 4 },
        { id: "28", x: 65, y: 400, radius: 4 },
      ],
      dotsVertical: [
        // 좌측 점들 (원래 위쪽 점들)
        { id: "1", x: 65, y: 800, radius: 4 },
        { id: "2", x: 65, y: 712.5, radius: 4 },
        { id: "3", x: 65, y: 625, radius: 4 },
        { id: "4", x: 65, y: 537.5, radius: 4 },
        { id: "5", x: 65, y: 450, radius: 4 },
        { id: "6", x: 65, y: 362.5, radius: 4 },
        { id: "7", x: 65, y: 275, radius: 4 },
        { id: "8", x: 65, y: 187.5, radius: 4 },
        { id: "9", x: 65, y: 100, radius: 4 },
        // 우측 점들 (원래 아래쪽 점들)
        { id: "10", x: 435, y: 800, radius: 4 },
        { id: "11", x: 435, y: 712.5, radius: 4 },
        { id: "12", x: 435, y: 625, radius: 4 },
        { id: "13", x: 435, y: 537.5, radius: 4 },
        { id: "14", x: 435, y: 450, radius: 4 },
        { id: "15", x: 435, y: 362.5, radius: 4 },
        { id: "16", x: 435, y: 275, radius: 4 },
        { id: "17", x: 435, y: 187.5, radius: 4 },
        { id: "18", x: 435, y: 100, radius: 4 },
        // 위쪽 점들 (원래 우측 점들)
        { id: "19", x: 100, y: 835, radius: 4 },
        { id: "20", x: 175, y: 835, radius: 4 },
        { id: "21", x: 250, y: 835, radius: 4 },
        { id: "22", x: 325, y: 835, radius: 4 },
        { id: "23", x: 400, y: 835, radius: 4 },
        // 아래쪽 점들 (원래 좌측 점들)
        { id: "24", x: 100, y: 65, radius: 4 },
        { id: "25", x: 175, y: 65, radius: 4 },
        { id: "26", x: 250, y: 65, radius: 4 },
        { id: "27", x: 325, y: 65, radius: 4 },
        { id: "28", x: 400, y: 65, radius: 4 },
      ],
      // 쿠션 설정
      cushionConfig: {
        // 쿠션의 사각형 시작 좌표 (80, 80)는 왼쪽 위 모서리
        x: 80,
        y: 80,
        width: 740,
        height: 340,
        stroke: "#2F4F4F", // 어두운 그린 테두리
        strokeWidth: Number("2"),
      },
      cushionConfigVertical: {
        // 쿠션의 사각형 시작 좌표 (80, 80)는 왼쪽 위 모서리
        x: 80,
        y: 80,
        width: 340,
        height: 740,
        stroke: "#2F4F4F", // 어두운 그린 테두리
        strokeWidth: Number("2"),
      },

      // 쿠션 눈금 설정
      topCushionLines: [
        { id: (1).toString(), points: [800, 80, 800, 100] }, // 8.75
        { id: (2).toString(), points: [791.25, 95, 791.25, 100] },
        { id: (3).toString(), points: [782.5, 95, 782.5, 100] },
        { id: (4).toString(), points: [773.75, 95, 773.75, 100] },
        { id: (5).toString(), points: [765, 95, 765, 100] },
        { id: (6).toString(), points: [756.25, 90, 756.25, 100] },
        { id: (7).toString(), points: [747.5, 95, 747.5, 100] },
        { id: (8).toString(), points: [738.75, 95, 738.75, 100] },
        { id: (9).toString(), points: [730, 95, 730, 100] },
        { id: (10).toString(), points: [721.25, 95, 721.25, 100] },
        { id: (11).toString(), points: [712.5, 80, 712.5, 100] },

        { id: (12).toString(), points: [703.75, 95, 703.75, 100] },
        { id: (13).toString(), points: [695, 95, 695, 100] },
        { id: (14).toString(), points: [686.25, 95, 686.25, 100] },
        { id: (15).toString(), points: [677.5, 95, 677.5, 100] },
        { id: (16).toString(), points: [668.75, 90, 668.75, 100] },
        { id: (17).toString(), points: [660, 95, 660, 100] },
        { id: (18).toString(), points: [651.25, 95, 651.25, 100] },
        { id: (19).toString(), points: [642.5, 95, 642.5, 100] },
        { id: (20).toString(), points: [633.75, 95, 633.75, 100] },
        { id: (21).toString(), points: [625, 80, 625, 100] },

        { id: (22).toString(), points: [616.25, 95, 616.25, 100] },
        { id: (23).toString(), points: [607.5, 95, 607.5, 100] },
        { id: (24).toString(), points: [598.75, 95, 598.75, 100] },
        { id: (25).toString(), points: [590, 95, 590, 100] },
        { id: (26).toString(), points: [581.25, 90, 581.25, 100] },
        { id: (27).toString(), points: [572.5, 95, 572.5, 100] },
        { id: (28).toString(), points: [563.75, 95, 563.75, 100] },
        { id: (29).toString(), points: [555, 95, 555, 100] },
        { id: (30).toString(), points: [546.25, 95, 546.25, 100] },
        { id: (31).toString(), points: [537.5, 80, 537.5, 100] },

        { id: (32).toString(), points: [528.75, 95, 528.75, 100] },
        { id: (33).toString(), points: [520, 95, 520, 100] },
        { id: (34).toString(), points: [511.25, 95, 511.25, 100] },
        { id: (35).toString(), points: [502.5, 95, 502.5, 100] },
        { id: (36).toString(), points: [493.75, 90, 493.75, 100] },
        { id: (37).toString(), points: [485, 95, 485, 100] },
        { id: (38).toString(), points: [476.25, 95, 476.25, 100] },
        { id: (39).toString(), points: [467.5, 95, 467.5, 100] },
        { id: (40).toString(), points: [458.75, 95, 458.75, 100] },
        { id: (41).toString(), points: [450, 80, 450, 100] },

        { id: (42).toString(), points: [441.25, 95, 441.25, 100] },
        { id: (43).toString(), points: [432.5, 95, 432.5, 100] },
        { id: (44).toString(), points: [423.75, 95, 423.75, 100] },
        { id: (45).toString(), points: [415, 95, 415, 100] },
        { id: (46).toString(), points: [406.25, 90, 406.25, 100] },
        { id: (47).toString(), points: [397.5, 95, 397.5, 100] },
        { id: (48).toString(), points: [388.75, 95, 388.75, 100] },
        { id: (49).toString(), points: [380, 95, 380, 100] },
        { id: (50).toString(), points: [371.25, 95, 371.25, 100] },
        { id: (51).toString(), points: [362.5, 80, 362.5, 100] },

        { id: (52).toString(), points: [353.75, 95, 353.75, 100] },
        { id: (53).toString(), points: [345, 95, 345, 100] },
        { id: (54).toString(), points: [336.25, 95, 336.25, 100] },
        { id: (55).toString(), points: [327.5, 95, 327.5, 100] },
        { id: (56).toString(), points: [318.75, 90, 318.75, 100] },
        { id: (57).toString(), points: [310, 95, 310, 100] },
        { id: (58).toString(), points: [301.25, 95, 301.25, 100] },
        { id: (59).toString(), points: [292.5, 95, 292.5, 100] },
        { id: (60).toString(), points: [283.75, 95, 283.75, 100] },
        { id: (61).toString(), points: [275, 80, 275, 100] },

        { id: (62).toString(), points: [266.25, 95, 266.25, 100] },
        { id: (63).toString(), points: [257.5, 95, 257.5, 100] },
        { id: (64).toString(), points: [248.75, 95, 248.75, 100] },
        { id: (65).toString(), points: [240, 95, 240, 100] },
        { id: (66).toString(), points: [231.25, 90, 231.25, 100] },
        { id: (67).toString(), points: [222.5, 95, 222.5, 100] },
        { id: (68).toString(), points: [213.75, 95, 213.75, 100] },
        { id: (69).toString(), points: [205, 95, 205, 100] },
        { id: (70).toString(), points: [196.25, 95, 196.25, 100] },
        { id: (71).toString(), points: [187.5, 80, 187.5, 100] },

        { id: (72).toString(), points: [178.75, 95, 178.75, 100] },
        { id: (73).toString(), points: [170, 95, 170, 100] },
        { id: (74).toString(), points: [161.25, 95, 161.25, 100] },
        { id: (75).toString(), points: [152.5, 95, 152.5, 100] },
        { id: (76).toString(), points: [143.75, 90, 143.75, 100] },
        { id: (77).toString(), points: [135, 95, 135, 100] },
        { id: (78).toString(), points: [126.25, 95, 126.25, 100] },
        { id: (79).toString(), points: [117.5, 95, 117.5, 100] },
        { id: (80).toString(), points: [108.75, 95, 108.75, 100] },

        { id: (81).toString(), points: [100, 80, 100, 100] },
      ],
      topCushionLinesVertical: [
        { id: (1).toString(), points: [100, 80, 100, 100] },
        { id: (2).toString(), points: [107.5, 95, 107.5, 100] },
        { id: (3).toString(), points: [115, 95, 115, 100] },
        { id: (4).toString(), points: [122.5, 95, 122.5, 100] },
        { id: (5).toString(), points: [130, 95, 130, 100] },
        { id: (6).toString(), points: [137.5, 90, 137.5, 100] },
        { id: (7).toString(), points: [145, 95, 145, 100] },
        { id: (8).toString(), points: [152.5, 95, 152.5, 100] },
        { id: (9).toString(), points: [160, 95, 160, 100] },
        { id: (10).toString(), points: [167.5, 95, 167.5, 100] },
        { id: (11).toString(), points: [175, 80, 175, 100] },

        { id: (12).toString(), points: [182.5, 95, 182.5, 100] },
        { id: (13).toString(), points: [190, 95, 190, 100] },
        { id: (14).toString(), points: [197.5, 95, 197.5, 100] },
        { id: (15).toString(), points: [205, 95, 205, 100] },
        { id: (16).toString(), points: [212.5, 90, 212.5, 100] },
        { id: (17).toString(), points: [220, 95, 220, 100] },
        { id: (18).toString(), points: [227.5, 95, 227.5, 100] },
        { id: (19).toString(), points: [235, 95, 235, 100] },
        { id: (20).toString(), points: [242.5, 95, 242.5, 100] },
        { id: (21).toString(), points: [250, 80, 250, 100] },

        { id: (22).toString(), points: [257.5, 95, 257.5, 100] },
        { id: (23).toString(), points: [265, 95, 265, 100] },
        { id: (24).toString(), points: [272.5, 95, 272.5, 100] },
        { id: (25).toString(), points: [280, 95, 280, 100] },
        { id: (26).toString(), points: [287.5, 90, 287.5, 100] },
        { id: (27).toString(), points: [295, 95, 295, 100] },
        { id: (28).toString(), points: [302.5, 95, 302.5, 100] },
        { id: (29).toString(), points: [310, 95, 310, 100] },
        { id: (30).toString(), points: [317.5, 95, 317.5, 100] },
        { id: (31).toString(), points: [325, 80, 325, 100] },

        { id: (32).toString(), points: [332.5, 95, 332.5, 100] },
        { id: (33).toString(), points: [340, 95, 340, 100] },
        { id: (34).toString(), points: [347.5, 95, 347.5, 100] },
        { id: (35).toString(), points: [355, 95, 355, 100] },
        { id: (36).toString(), points: [362.5, 90, 362.5, 100] },
        { id: (37).toString(), points: [370, 95, 370, 100] },
        { id: (38).toString(), points: [377.5, 95, 377.5, 100] },
        { id: (39).toString(), points: [385, 95, 385, 100] },
        { id: (40).toString(), points: [392.5, 95, 392.5, 100] },

        { id: (41).toString(), points: [400, 80, 400, 100] },
      ],
      bottomCushionLines: [
        { id: (1).toString(), points: [800, 400, 800, 420] },
        { id: (2).toString(), points: [791.25, 400, 791.25, 405] },
        { id: (3).toString(), points: [782.5, 400, 782.5, 405] },
        { id: (4).toString(), points: [773.75, 400, 773.75, 405] },
        { id: (5).toString(), points: [765, 400, 765, 405] },
        { id: (6).toString(), points: [756.25, 400, 756.25, 410] },
        { id: (7).toString(), points: [747.5, 400, 747.5, 405] },
        { id: (8).toString(), points: [738.75, 400, 738.75, 405] },
        { id: (9).toString(), points: [730, 400, 730, 405] },
        { id: (10).toString(), points: [721.25, 400, 721.25, 405] },
        { id: (11).toString(), points: [712.5, 400, 712.5, 420] },

        { id: (12).toString(), points: [703.75, 400, 703.75, 405] },
        { id: (13).toString(), points: [695, 400, 695, 405] },
        { id: (14).toString(), points: [686.25, 400, 686.25, 405] },
        { id: (15).toString(), points: [677.5, 400, 677.5, 405] },
        { id: (16).toString(), points: [668.75, 400, 668.75, 410] },
        { id: (17).toString(), points: [660, 400, 660, 405] },
        { id: (18).toString(), points: [651.25, 400, 651.25, 405] },
        { id: (19).toString(), points: [642.5, 400, 642.5, 405] },
        { id: (20).toString(), points: [633.75, 400, 633.75, 405] },
        { id: (21).toString(), points: [625, 400, 625, 420] },

        { id: (22).toString(), points: [616.25, 400, 616.25, 405] },
        { id: (23).toString(), points: [607.5, 400, 607.5, 405] },
        { id: (24).toString(), points: [598.75, 400, 598.75, 405] },
        { id: (25).toString(), points: [590, 400, 590, 405] },
        { id: (26).toString(), points: [581.25, 400, 581.25, 410] },
        { id: (27).toString(), points: [572.5, 400, 572.5, 405] },
        { id: (28).toString(), points: [563.75, 400, 563.75, 405] },
        { id: (29).toString(), points: [555, 400, 555, 405] },
        { id: (30).toString(), points: [546.25, 400, 546.25, 405] },
        { id: (31).toString(), points: [537.5, 400, 537.5, 420] },

        { id: (32).toString(), points: [528.75, 400, 528.75, 405] },
        { id: (33).toString(), points: [520, 400, 520, 405] },
        { id: (34).toString(), points: [511.25, 400, 511.25, 405] },
        { id: (35).toString(), points: [502.5, 400, 502.5, 405] },
        { id: (36).toString(), points: [493.75, 400, 493.75, 410] },
        { id: (37).toString(), points: [485, 400, 485, 405] },
        { id: (38).toString(), points: [476.25, 400, 476.25, 405] },
        { id: (39).toString(), points: [467.5, 400, 467.5, 405] },
        { id: (40).toString(), points: [458.75, 400, 458.75, 405] },
        { id: (41).toString(), points: [450, 400, 450, 420] },

        { id: (42).toString(), points: [441.25, 400, 441.25, 405] },
        { id: (43).toString(), points: [432.5, 400, 432.5, 405] },
        { id: (44).toString(), points: [423.75, 400, 423.75, 405] },
        { id: (45).toString(), points: [415, 400, 415, 405] },
        { id: (46).toString(), points: [406.25, 400, 406.25, 410] },
        { id: (47).toString(), points: [397.5, 400, 397.5, 405] },
        { id: (48).toString(), points: [388.75, 400, 388.75, 405] },
        { id: (49).toString(), points: [380, 400, 380, 405] },
        { id: (50).toString(), points: [371.25, 400, 371.25, 405] },
        { id: (51).toString(), points: [362.5, 400, 362.5, 420] },

        { id: (52).toString(), points: [353.75, 400, 353.75, 405] },
        { id: (53).toString(), points: [345, 400, 345, 405] },
        { id: (54).toString(), points: [336.25, 400, 336.25, 405] },
        { id: (55).toString(), points: [327.5, 400, 327.5, 405] },
        { id: (56).toString(), points: [318.75, 400, 318.75, 410] },
        { id: (57).toString(), points: [310, 400, 310, 405] },
        { id: (58).toString(), points: [301.25, 400, 301.25, 405] },
        { id: (59).toString(), points: [292.5, 400, 292.5, 405] },
        { id: (60).toString(), points: [283.75, 400, 283.75, 405] },
        { id: (61).toString(), points: [275, 400, 275, 420] },

        { id: (62).toString(), points: [266.25, 400, 266.25, 405] },
        { id: (63).toString(), points: [257.5, 400, 257.5, 405] },
        { id: (64).toString(), points: [248.75, 400, 248.75, 405] },
        { id: (65).toString(), points: [240, 400, 240, 405] },
        { id: (66).toString(), points: [231.25, 400, 231.25, 410] },
        { id: (67).toString(), points: [222.5, 400, 222.5, 405] },
        { id: (68).toString(), points: [213.75, 400, 213.75, 405] },
        { id: (69).toString(), points: [205, 400, 205, 405] },
        { id: (70).toString(), points: [196.25, 400, 196.25, 405] },
        { id: (71).toString(), points: [187.5, 400, 187.5, 420] },

        { id: (72).toString(), points: [178.75, 400, 178.75, 405] },
        { id: (73).toString(), points: [170, 400, 170, 405] },
        { id: (74).toString(), points: [161.25, 400, 161.25, 405] },
        { id: (75).toString(), points: [152.5, 400, 152.5, 405] },
        { id: (76).toString(), points: [143.75, 400, 143.75, 410] },
        { id: (77).toString(), points: [135, 400, 135, 405] },
        { id: (78).toString(), points: [126.25, 400, 126.25, 405] },
        { id: (79).toString(), points: [117.5, 400, 117.5, 405] },
        { id: (80).toString(), points: [108.75, 400, 108.75, 405] },
        { id: (81).toString(), points: [100, 400, 100, 420] },
      ],
      bottomCushionLinesVertical: [
        { id: (1).toString(), points: [100, 820, 100, 800] },
        { id: (2).toString(), points: [107.5, 805, 107.5, 800] },
        { id: (3).toString(), points: [115, 805, 115, 800] },
        { id: (4).toString(), points: [122.5, 805, 122.5, 800] },
        { id: (5).toString(), points: [130, 805, 130, 800] },
        { id: (6).toString(), points: [137.5, 810, 137.5, 800] },
        { id: (7).toString(), points: [145, 805, 145, 800] },
        { id: (8).toString(), points: [152.5, 805, 152.5, 800] },
        { id: (9).toString(), points: [160, 805, 160, 800] },
        { id: (10).toString(), points: [167.5, 805, 167.5, 800] },
        { id: (11).toString(), points: [175, 820, 175, 800] },

        { id: (12).toString(), points: [182.5, 805, 182.5, 800] },
        { id: (13).toString(), points: [190, 805, 190, 800] },
        { id: (14).toString(), points: [197.5, 805, 197.5, 800] },
        { id: (15).toString(), points: [205, 805, 205, 800] },
        { id: (16).toString(), points: [212.5, 810, 212.5, 800] },
        { id: (17).toString(), points: [220, 805, 220, 800] },
        { id: (18).toString(), points: [227.5, 805, 227.5, 800] },
        { id: (19).toString(), points: [235, 805, 235, 800] },
        { id: (20).toString(), points: [242.5, 805, 242.5, 800] },
        { id: (21).toString(), points: [250, 820, 250, 800] },

        { id: (22).toString(), points: [257.5, 805, 257.5, 800] },
        { id: (23).toString(), points: [265, 805, 265, 800] },
        { id: (24).toString(), points: [272.5, 805, 272.5, 800] },
        { id: (25).toString(), points: [280, 805, 280, 800] },
        { id: (26).toString(), points: [287.5, 810, 287.5, 800] },
        { id: (27).toString(), points: [295, 805, 295, 800] },
        { id: (28).toString(), points: [302.5, 805, 302.5, 800] },
        { id: (29).toString(), points: [310, 805, 310, 800] },
        { id: (30).toString(), points: [317.5, 805, 317.5, 800] },
        { id: (31).toString(), points: [325, 820, 325, 800] },

        { id: (32).toString(), points: [332.5, 805, 332.5, 800] },
        { id: (33).toString(), points: [340, 805, 340, 800] },
        { id: (34).toString(), points: [347.5, 805, 347.5, 800] },
        { id: (35).toString(), points: [355, 805, 355, 800] },
        { id: (36).toString(), points: [362.5, 810, 362.5, 800] },
        { id: (37).toString(), points: [370, 805, 370, 800] },
        { id: (38).toString(), points: [377.5, 805, 377.5, 800] },
        { id: (39).toString(), points: [385, 805, 385, 800] },
        { id: (40).toString(), points: [392.5, 805, 392.5, 800] },

        { id: (41).toString(), points: [400, 820, 400, 800] },
      ],
      leftCushionLines: [
        { id: (1).toString(), points: [80, 100, 100, 100] },
        { id: (2).toString(), points: [95, 107.5, 100, 107.5] },
        { id: (3).toString(), points: [95, 115, 100, 115] },
        { id: (4).toString(), points: [95, 122.5, 100, 122.5] },
        { id: (5).toString(), points: [95, 130, 100, 130] },
        { id: (6).toString(), points: [90, 137.5, 100, 137.5] },
        { id: (7).toString(), points: [95, 145, 100, 145] },
        { id: (8).toString(), points: [95, 152.5, 100, 152.5] },
        { id: (9).toString(), points: [95, 160, 100, 160] },
        { id: (10).toString(), points: [95, 167.5, 100, 167.5] },

        { id: (11).toString(), points: [80, 175, 100, 175] },
        { id: (12).toString(), points: [95, 182.5, 100, 182.5] },
        { id: (13).toString(), points: [95, 190, 100, 190] },
        { id: (14).toString(), points: [95, 197.5, 100, 197.5] },
        { id: (15).toString(), points: [95, 205, 100, 205] },
        { id: (16).toString(), points: [90, 212.5, 100, 212.5] },
        { id: (17).toString(), points: [95, 220, 100, 220] },
        { id: (18).toString(), points: [95, 227.5, 100, 227.5] },
        { id: (19).toString(), points: [95, 235, 100, 235] },
        { id: (20).toString(), points: [95, 242.5, 100, 242.5] },

        { id: (21).toString(), points: [80, 250, 100, 250] },
        { id: (22).toString(), points: [95, 257.5, 100, 257.5] },
        { id: (23).toString(), points: [95, 265, 100, 265] },
        { id: (24).toString(), points: [95, 272.5, 100, 272.5] },
        { id: (25).toString(), points: [95, 280, 100, 280] },
        { id: (26).toString(), points: [90, 287.5, 100, 287.5] },
        { id: (27).toString(), points: [95, 295, 100, 295] },
        { id: (28).toString(), points: [95, 302.5, 100, 302.5] },
        { id: (29).toString(), points: [95, 310, 100, 310] },
        { id: (30).toString(), points: [95, 317.5, 100, 317.5] },

        { id: (31).toString(), points: [80, 325, 100, 325] },
        { id: (32).toString(), points: [95, 332.5, 100, 332.5] },
        { id: (33).toString(), points: [95, 340, 100, 340] },
        { id: (34).toString(), points: [95, 347.5, 100, 347.5] },
        { id: (35).toString(), points: [95, 355, 100, 355] },
        { id: (36).toString(), points: [90, 362.5, 100, 362.5] },
        { id: (37).toString(), points: [95, 370, 100, 370] },
        { id: (38).toString(), points: [95, 377.5, 100, 377.5] },
        { id: (39).toString(), points: [95, 385, 100, 385] },
        { id: (40).toString(), points: [95, 392.5, 100, 392.5] },

        { id: (41).toString(), points: [80, 400, 100, 400] },
      ],
      leftCushionLinesVertical: [
        { id: (1).toString(), points: [80, 800, 100, 800] }, // 왼쪽에서 시작
        { id: (2).toString(), points: [95, 791.25, 100, 791.25] },
        { id: (3).toString(), points: [95, 782.5, 100, 782.5] },
        { id: (4).toString(), points: [95, 773.75, 100, 773.75] },
        { id: (5).toString(), points: [95, 765, 100, 765] },
        { id: (6).toString(), points: [90, 756.25, 100, 756.25] },
        { id: (7).toString(), points: [95, 747.5, 100, 747.5] },
        { id: (8).toString(), points: [95, 738.75, 100, 738.75] },
        { id: (9).toString(), points: [95, 730, 100, 730] },
        { id: (10).toString(), points: [95, 721.25, 100, 721.25] },
        { id: (11).toString(), points: [80, 712.5, 100, 712.5] },

        { id: (12).toString(), points: [95, 703.75, 100, 703.75] },
        { id: (13).toString(), points: [95, 695, 100, 695] },
        { id: (14).toString(), points: [95, 686.25, 100, 686.25] },
        { id: (15).toString(), points: [95, 677.5, 100, 677.5] },
        { id: (16).toString(), points: [90, 668.75, 100, 668.75] },
        { id: (17).toString(), points: [95, 660, 100, 660] },
        { id: (18).toString(), points: [95, 651.25, 100, 651.25] },
        { id: (19).toString(), points: [95, 642.5, 100, 642.5] },
        { id: (20).toString(), points: [95, 633.75, 100, 633.75] },
        { id: (21).toString(), points: [80, 625, 100, 625] },

        { id: (22).toString(), points: [95, 616.25, 100, 616.25] },
        { id: (23).toString(), points: [95, 607.5, 100, 607.5] },
        { id: (24).toString(), points: [95, 598.75, 100, 598.75] },
        { id: (25).toString(), points: [95, 590, 100, 590] },
        { id: (26).toString(), points: [90, 581.25, 100, 581.25] },
        { id: (27).toString(), points: [95, 572.5, 100, 572.5] },
        { id: (28).toString(), points: [95, 563.75, 100, 563.75] },
        { id: (29).toString(), points: [95, 555, 100, 555] },
        { id: (30).toString(), points: [95, 546.25, 100, 546.25] },
        { id: (31).toString(), points: [80, 537.5, 100, 537.5] },

        { id: (32).toString(), points: [95, 528.75, 100, 528.75] },
        { id: (33).toString(), points: [95, 520, 100, 520] },
        { id: (34).toString(), points: [95, 511.25, 100, 511.25] },
        { id: (35).toString(), points: [95, 502.5, 100, 502.5] },
        { id: (36).toString(), points: [90, 493.75, 100, 493.75] },
        { id: (37).toString(), points: [95, 485, 100, 485] },
        { id: (38).toString(), points: [95, 476.25, 100, 476.25] },
        { id: (39).toString(), points: [95, 467.5, 100, 467.5] },
        { id: (40).toString(), points: [95, 458.75, 100, 458.75] },
        { id: (41).toString(), points: [80, 450, 100, 450] },

        { id: (42).toString(), points: [95, 441.25, 100, 441.25] },
        { id: (43).toString(), points: [95, 432.5, 100, 432.5] },
        { id: (44).toString(), points: [95, 423.75, 100, 423.75] },
        { id: (45).toString(), points: [95, 415, 100, 415] },
        { id: (46).toString(), points: [90, 406.25, 100, 406.25] },
        { id: (47).toString(), points: [95, 397.5, 100, 397.5] },
        { id: (48).toString(), points: [95, 388.75, 100, 388.75] },
        { id: (49).toString(), points: [95, 380, 100, 380] },
        { id: (50).toString(), points: [95, 371.25, 100, 371.25] },
        { id: (51).toString(), points: [80, 362.5, 100, 362.5] },

        { id: (52).toString(), points: [95, 353.75, 100, 353.75] },
        { id: (53).toString(), points: [95, 345, 100, 345] },
        { id: (54).toString(), points: [95, 336.25, 100, 336.25] },
        { id: (55).toString(), points: [95, 327.5, 100, 327.5] },
        { id: (56).toString(), points: [90, 318.75, 100, 318.75] },
        { id: (57).toString(), points: [95, 310, 100, 310] },
        { id: (58).toString(), points: [95, 301.25, 100, 301.25] },
        { id: (59).toString(), points: [95, 292.5, 100, 292.5] },
        { id: (60).toString(), points: [95, 283.75, 100, 283.75] },
        { id: (61).toString(), points: [80, 275, 100, 275] },

        { id: (62).toString(), points: [95, 266.25, 100, 266.25] },
        { id: (63).toString(), points: [95, 257.5, 100, 257.5] },
        { id: (64).toString(), points: [95, 248.75, 100, 248.75] },
        { id: (65).toString(), points: [95, 240, 100, 240] },
        { id: (66).toString(), points: [90, 231.25, 100, 231.25] },
        { id: (67).toString(), points: [95, 222.5, 100, 222.5] },
        { id: (68).toString(), points: [95, 213.75, 100, 213.75] },
        { id: (69).toString(), points: [95, 205, 100, 205] },
        { id: (70).toString(), points: [95, 196.25, 100, 196.25] },
        { id: (71).toString(), points: [80, 187.5, 100, 187.5] },

        { id: (72).toString(), points: [95, 178.75, 100, 178.75] },
        { id: (73).toString(), points: [95, 170, 100, 170] },
        { id: (74).toString(), points: [95, 161.25, 100, 161.25] },
        { id: (75).toString(), points: [95, 152.5, 100, 152.5] },
        { id: (76).toString(), points: [90, 143.75, 100, 143.75] },
        { id: (77).toString(), points: [95, 135, 100, 135] },
        { id: (78).toString(), points: [95, 126.25, 100, 126.25] },
        { id: (79).toString(), points: [95, 117.5, 100, 117.5] },
        { id: (80).toString(), points: [95, 108.75, 100, 108.75] },
        { id: (81).toString(), points: [80, 100, 100, 100] },
      ],
      rightCushionLines: [
        { id: (1).toString(), points: [800, 100, 820, 100] }, // 7.5
        { id: (2).toString(), points: [800, 107.5, 805, 107.5] },
        { id: (3).toString(), points: [800, 115, 805, 115] },
        { id: (4).toString(), points: [800, 122.5, 805, 122.5] },
        { id: (5).toString(), points: [800, 137.5, 810, 137.5] },
        { id: (6).toString(), points: [800, 130, 805, 130] },
        { id: (7).toString(), points: [800, 145, 805, 145] },
        { id: (8).toString(), points: [800, 152.5, 805, 152.5] },
        { id: (9).toString(), points: [800, 160, 805, 160] },
        { id: (10).toString(), points: [800, 167.5, 805, 167.5] },

        { id: (11).toString(), points: [800, 175, 820, 175] },
        { id: (12).toString(), points: [800, 182.5, 805, 182.5] },
        { id: (13).toString(), points: [800, 190, 805, 190] },
        { id: (14).toString(), points: [800, 197.5, 805, 197.5] },
        { id: (15).toString(), points: [800, 205, 805, 205] },
        { id: (16).toString(), points: [800, 212.5, 810, 212.5] },
        { id: (17).toString(), points: [800, 220, 805, 220] },
        { id: (18).toString(), points: [800, 227.5, 805, 227.5] },
        { id: (19).toString(), points: [800, 235, 805, 235] },
        { id: (20).toString(), points: [800, 242.5, 805, 242.5] },

        { id: (21).toString(), points: [800, 250, 820, 250] },
        { id: (22).toString(), points: [800, 257.5, 805, 257.5] },
        { id: (23).toString(), points: [800, 265, 805, 265] },
        { id: (24).toString(), points: [800, 272.5, 805, 272.5] },
        { id: (25).toString(), points: [800, 280, 810, 280] },
        { id: (26).toString(), points: [800, 287.5, 805, 287.5] },
        { id: (27).toString(), points: [800, 295, 805, 295] },
        { id: (28).toString(), points: [800, 302.5, 805, 302.5] },
        { id: (29).toString(), points: [800, 310, 805, 310] },
        { id: (30).toString(), points: [800, 317.5, 805, 317.5] },

        { id: (31).toString(), points: [800, 325, 820, 325] },
        { id: (32).toString(), points: [800, 332.5, 805, 332.5] },
        { id: (33).toString(), points: [800, 340, 805, 340] },
        { id: (34).toString(), points: [800, 347.5, 805, 347.5] },
        { id: (35).toString(), points: [800, 355, 805, 355] },
        { id: (36).toString(), points: [800, 362.5, 810, 362.5] },
        { id: (37).toString(), points: [800, 370, 805, 370] },
        { id: (38).toString(), points: [800, 377.5, 805, 377.5] },
        { id: (39).toString(), points: [800, 385, 805, 385] },
        { id: (40).toString(), points: [800, 392.5, 805, 392.5] },
        { id: (41).toString(), points: [800, 400, 820, 400] },
      ],
      rightCushionLinesVertical: [
        { id: (1).toString(), points: [400, 800, 420, 800] }, // 왼쪽에서 시작
        { id: (2).toString(), points: [400, 791.25, 405, 791.25] },
        { id: (3).toString(), points: [400, 782.5, 405, 782.5] },
        { id: (4).toString(), points: [400, 773.75, 405, 773.75] },
        { id: (5).toString(), points: [400, 765, 405, 765] },
        { id: (6).toString(), points: [400, 756.25, 410, 756.25] },
        { id: (7).toString(), points: [400, 747.5, 405, 747.5] },
        { id: (8).toString(), points: [400, 738.75, 405, 738.75] },
        { id: (9).toString(), points: [400, 730, 405, 730] },
        { id: (10).toString(), points: [400, 721.25, 405, 721.25] },
        { id: (11).toString(), points: [400, 712.5, 420, 712.5] },

        { id: (12).toString(), points: [400, 703.75, 405, 703.75] },
        { id: (13).toString(), points: [400, 695, 405, 695] },
        { id: (14).toString(), points: [400, 686.25, 405, 686.25] },
        { id: (15).toString(), points: [400, 677.5, 405, 677.5] },
        { id: (16).toString(), points: [400, 668.75, 410, 668.75] },
        { id: (17).toString(), points: [400, 660, 405, 660] },
        { id: (18).toString(), points: [400, 651.25, 405, 651.25] },
        { id: (19).toString(), points: [400, 642.5, 405, 642.5] },
        { id: (20).toString(), points: [400, 633.75, 405, 633.75] },
        { id: (21).toString(), points: [400, 625, 420, 625] },

        { id: (22).toString(), points: [400, 616.25, 405, 616.25] },
        { id: (23).toString(), points: [400, 607.5, 405, 607.5] },
        { id: (24).toString(), points: [400, 598.75, 405, 598.75] },
        { id: (25).toString(), points: [400, 590, 405, 590] },
        { id: (26).toString(), points: [400, 581.25, 410, 581.25] },
        { id: (27).toString(), points: [400, 572.5, 405, 572.5] },
        { id: (28).toString(), points: [400, 563.75, 405, 563.75] },
        { id: (29).toString(), points: [400, 555, 405, 555] },
        { id: (30).toString(), points: [400, 546.25, 405, 546.25] },
        { id: (31).toString(), points: [400, 537.5, 420, 537.5] },

        { id: (32).toString(), points: [400, 528.75, 405, 528.75] },
        { id: (33).toString(), points: [400, 520, 405, 520] },
        { id: (34).toString(), points: [400, 511.25, 405, 511.25] },
        { id: (35).toString(), points: [400, 502.5, 405, 502.5] },
        { id: (36).toString(), points: [400, 493.75, 410, 493.75] },
        { id: (37).toString(), points: [400, 485, 405, 485] },
        { id: (38).toString(), points: [400, 476.25, 405, 476.25] },
        { id: (39).toString(), points: [400, 467.5, 405, 467.5] },
        { id: (40).toString(), points: [400, 458.75, 405, 458.75] },
        { id: (41).toString(), points: [400, 450, 420, 450] },

        { id: (42).toString(), points: [400, 441.25, 405, 441.25] },
        { id: (43).toString(), points: [400, 432.5, 405, 432.5] },
        { id: (44).toString(), points: [400, 423.75, 405, 423.75] },
        { id: (45).toString(), points: [400, 415, 405, 415] },
        { id: (46).toString(), points: [400, 406.25, 410, 406.25] },
        { id: (47).toString(), points: [400, 397.5, 405, 397.5] },
        { id: (48).toString(), points: [400, 388.75, 405, 388.75] },
        { id: (49).toString(), points: [400, 380, 405, 380] },
        { id: (50).toString(), points: [400, 371.25, 405, 371.25] },
        { id: (51).toString(), points: [400, 362.5, 420, 362.5] },

        { id: (52).toString(), points: [400, 362.5, 420, 362.5] },
        { id: (53).toString(), points: [400, 353.75, 405, 353.75] },
        { id: (54).toString(), points: [400, 345, 405, 345] },
        { id: (55).toString(), points: [400, 336.25, 405, 336.25] },
        { id: (56).toString(), points: [400, 327.5, 405, 327.5] },
        { id: (57).toString(), points: [400, 318.75, 410, 318.75] },
        { id: (58).toString(), points: [400, 310, 405, 310] },
        { id: (59).toString(), points: [400, 301.25, 405, 301.25] },
        { id: (60).toString(), points: [400, 292.5, 405, 292.5] },
        { id: (61).toString(), points: [400, 283.75, 405, 283.75] },
        { id: (62).toString(), points: [400, 275, 420, 275] },

        { id: (63).toString(), points: [400, 266.25, 405, 266.25] },
        { id: (64).toString(), points: [400, 257.5, 405, 257.5] },
        { id: (65).toString(), points: [400, 248.75, 405, 248.75] },
        { id: (66).toString(), points: [400, 240, 405, 240] },
        { id: (67).toString(), points: [400, 231.25, 410, 231.25] },
        { id: (68).toString(), points: [400, 222.5, 405, 222.5] },
        { id: (69).toString(), points: [400, 213.75, 405, 213.75] },
        { id: (70).toString(), points: [400, 205, 405, 205] },
        { id: (71).toString(), points: [400, 196.25, 405, 196.25] },
        { id: (72).toString(), points: [400, 187.5, 420, 187.5] },

        { id: (73).toString(), points: [400, 178.75, 405, 178.75] },
        { id: (74).toString(), points: [400, 170, 405, 170] },
        { id: (75).toString(), points: [400, 161.25, 405, 161.25] },
        { id: (76).toString(), points: [400, 152.5, 405, 152.5] },
        { id: (77).toString(), points: [400, 143.75, 410, 143.75] },
        { id: (78).toString(), points: [400, 135, 405, 135] },
        { id: (79).toString(), points: [400, 126.25, 405, 126.25] },
        { id: (80).toString(), points: [400, 117.5, 405, 117.5] },
        { id: (81).toString(), points: [400, 108.75, 405, 108.75] },
        { id: (82).toString(), points: [400, 100, 420, 100] },
      ],

      // 테이블 외부 필드 설정
      tableConfig: {
        x: 100,
        y: 100,
        width: 700,
        height: 300,
      },
      tableConfigVertical: {
        x: 100,
        y: 100,
        width: 300,
        height: 700,
      },
      // 테이블 내부 필드 설정
      tableConfigInner: {
        x: 100 + (700 - 670) / 2, // 중앙 배치 계산
        y: 100 + (300 - 270) / 2,
        width: 670,
        height: 270,
      },
      tableConfigInnerVertical: {
        x: 100 + (700 - 670) / 2, // 중앙 배치 계산
        y: 100 + (300 - 270) / 2,
        width: 270,
        height: 670,
      },

      // 테이블 필드 수직 격자 선 설정(세로)
      verticalLines: [
        { id: (1).toString(), points: [100, 100, 100, 400] },
        {
          id: (2).toString(),
          points: [143.75, 100, 143.75, 400],
          dash: [6, 3],
        }, // 43.75
        /* { id: 1, points: [158.34, 100, 158.34, 400] }, // 29.17 칸 안에 줄 3개  */

        { id: (3).toString(), points: [187.5, 100, 187.5, 400] },
        {
          id: (4).toString(),
          points: [231.25, 100, 231.25, 400],
          dash: [6, 3],
        },

        { id: (5).toString(), points: [275, 100, 275, 400] },
        {
          id: (6).toString(),
          points: [318.75, 100, 318.75, 400],
          dash: [6, 3],
        },

        { id: (7).toString(), points: [362.5, 100, 362.5, 400] },
        {
          id: (8).toString(),
          points: [406.25, 100, 406.25, 400],
          dash: [6, 3],
        },

        { id: (9).toString(), points: [450, 100, 450, 400] },
        {
          id: (10).toString(),
          points: [493.75, 100, 493.75, 400],
          dash: [6, 3],
        },

        { id: (11).toString(), points: [537.5, 100, 537.5, 400] },
        {
          id: (12).toString(),
          points: [581.25, 100, 581.25, 400],
          dash: [6, 3],
        },

        { id: (13).toString(), points: [625, 100, 625, 400] },
        {
          id: (14).toString(),
          points: [668.75, 100, 668.75, 400],
          dash: [6, 3],
        },

        { id: (15).toString(), points: [712.5, 100, 712.5, 400] },
        {
          id: (16).toString(),
          points: [756.25, 100, 756.25, 400],
          dash: [6, 3],
        },

        { id: (17).toString(), points: [800, 100, 800, 400] },
      ],
      verticalLinesVertical: [
        { id: (1).toString(), points: [100, 100, 100, 800] },
        { id: (2).toString(), points: [137.5, 100, 137.5, 800], dash: [6, 3] }, // 43.75
        /* { id: 1, points: [158.34, 100, 158.34, 400] }, // 29.17 칸 안에 줄 3개  */

        { id: (3).toString(), points: [175, 100, 175, 800] },
        { id: (4).toString(), points: [212.5, 100, 212.5, 800], dash: [6, 3] },

        { id: (5).toString(), points: [250, 100, 250, 800] },

        { id: (6).toString(), points: [287.5, 100, 287.5, 800], dash: [6, 3] },

        { id: (7).toString(), points: [325, 100, 325, 800] },
        { id: (8).toString(), points: [362.5, 100, 362.5, 800], dash: [6, 3] },

        { id: (9).toString(), points: [400, 100, 400, 800] },
        { id: (10).toString(), points: [800, 100, 800, 400] },
      ],
      // 테이블 필드 수평 격자 선 설정(가로)
      horizontalLines: [
        { id: (1).toString(), points: [100, 100, 800, 100] },
        { id: (2).toString(), points: [100, 137.5, 800, 137.5], dash: [6, 3] }, // 37.5

        { id: (3).toString(), points: [100, 175, 800, 175] },
        { id: (4).toString(), points: [100, 212.5, 800, 212.5], dash: [6, 3] },

        { id: (5).toString(), points: [100, 250, 800, 250] },
        { id: (6).toString(), points: [100, 287.5, 800, 287.5], dash: [6, 3] },

        { id: (7).toString(), points: [100, 325, 800, 325] },
        { id: (8).toString(), points: [100, 362.5, 800, 362.5], dash: [6, 3] },

        { id: (9).toString(), points: [100, 400, 800, 400] },
      ],
      horizontalLinesVertical: [
        { id: (1).toString(), points: [100, 100, 400, 100] },
        {
          id: (2).toString(),
          points: [100, 143.75, 400, 143.75],
          dash: [6, 3],
        },

        { id: (3).toString(), points: [100, 187.5, 400, 187.5] },
        {
          id: (4).toString(),
          points: [100, 231.25, 400, 231.25],
          dash: [6, 3],
        },

        { id: (5).toString(), points: [100, 275, 400, 275] },
        {
          id: (6).toString(),
          points: [100, 318.75, 400, 318.75],
          dash: [6, 3],
        },

        { id: (7).toString(), points: [100, 362.5, 400, 362.5] },
        {
          id: (8).toString(),
          points: [100, 406.25, 400, 406.25],
          dash: [6, 3],
        },

        { id: (9).toString(), points: [100, 450, 400, 450] },
        {
          id: (10).toString(),
          points: [100, 493.75, 400, 493.75],
          dash: [6, 3],
        },

        { id: (11).toString(), points: [100, 537.5, 400, 537.5] },
        {
          id: (12).toString(),
          points: [100, 581.25, 400, 581.25],
          dash: [6, 3],
        },

        { id: (13).toString(), points: [100, 625, 400, 625] },
        {
          id: (14).toString(),
          points: [100, 668.75, 400, 668.75],
          dash: [6, 3],
        },

        { id: (15).toString(), points: [100, 712.5, 400, 712.5] },
        {
          id: (16).toString(),
          points: [100, 756.25, 400, 756.25],
          dash: [6, 3],
        },

        { id: (17).toString(), points: [100, 800, 400, 800] },
      ],

      // 토글 큐볼 설정
      toggleCueBallConfig: {
        x: 100,
        y: 20,
        radius: 13,
      },
      // 토글 타겟볼 설정
      toggleTargetBallConfig: {
        x: 140,
        y: 20,
        radius: 13,
      },
      // 토글 상대볼 설정
      toggleOpponentBallConfig: {
        x: 180,
        y: 20,
        radius: 13,
      },

      // 라인 제거 표시 바 설정
      redBarConfig1: {
        points: [215, 15, 230, 28],
        stroke: "red",
        strokeWidth: 10,
        lineCap: "round",
      },
      redBarConfig2: {
        points: [215, 28, 230, 15],
        stroke: "red",
        strokeWidth: 10,
        lineCap: "round",
      },

      // 클릭한 포인트 제거 아이콘 설정
      removeClickedPointConfig: {
        x: 270,
        y: 20,
        radius: 8,
      },

      // 큐볼, 목표볼, 상대볼 설정
      cueBallConfig: {
        x: 450,
        y: 212.5,
        radius: 13,
        draggable: true,
        // dragmove가 특정 역역에서만 되게 할 수 있는 방법
        dragBoundFunc(pos) {
          const minX = 114;
          const maxX = 786;
          const minY = 114;
          const maxY = 386;

          // X, Y 좌표를 제한된 범위 내로 조정
          const newX = Math.max(minX, Math.min(maxX, pos.x));
          const newY = Math.max(minY, Math.min(maxY, pos.y));

          return {
            x: newX,
            y: newY,
          };
        },
      },
      cueBallConfigVertical: {
        x: 212.5, // 500 - 212.5 (x 값이 왼쪽부터 시작하므로 수직 방향에서는 해당 방향의 (너비 - 수평 방향에서의 y 값)을 빼주면 된다)
        y: 450,
        radius: 13,
        draggable: true,
        // dragmove가 특정 역역에서만 되게 할 수 있는 방법
        dragBoundFunc(pos) {
          const minX = 114;
          const maxX = 386;
          const minY = 114;
          const maxY = 786;

          // X, Y 좌표를 제한된 범위 내로 조정
          const newX = Math.max(minX, Math.min(maxX, pos.x));
          const newY = Math.max(minY, Math.min(maxY, pos.y));

          return {
            x: newX,
            y: newY,
          };
        },
      },
      targetBallConfig: {
        x: 450,
        y: 250,
        radius: 13,
        draggable: true,
        dragBoundFunc(pos) {
          const minX = 114;
          const maxX = 786;
          const minY = 114;
          const maxY = 386;

          // X, Y 좌표를 제한된 범위 내로 조정
          const newX = Math.max(minX, Math.min(maxX, pos.x));
          const newY = Math.max(minY, Math.min(maxY, pos.y));

          return {
            x: newX,
            y: newY,
          };
        },
      },
      targetBallConfigVertical: {
        x: 250,
        y: 450,
        radius: 13,
        draggable: true,
        dragBoundFunc(pos) {
          const minX = 114;
          const maxX = 386;
          const minY = 114;
          const maxY = 786;

          // X, Y 좌표를 제한된 범위 내로 조정
          const newX = Math.max(minX, Math.min(maxX, pos.x));
          const newY = Math.max(minY, Math.min(maxY, pos.y));

          return {
            x: newX,
            y: newY,
          };
        },
      },
      opponentBallConfig: {
        x: 450,
        y: 287.5,
        radius: 13,
        draggable: true,
        dragBoundFunc(pos) {
          const minX = 114;
          const maxX = 786;
          const minY = 114;
          const maxY = 386;

          // X, Y 좌표를 제한된 범위 내로 조정
          const newX = Math.max(minX, Math.min(maxX, pos.x));
          const newY = Math.max(minY, Math.min(maxY, pos.y));

          return {
            x: newX,
            y: newY,
          };
        },
      },
      opponentBallConfigVertical: {
        x: 287.5,
        y: 450,
        radius: 13,
        draggable: true,
        dragBoundFunc(pos) {
          const minX = 114;
          const maxX = 386;
          const minY = 114;
          const maxY = 786;

          // X, Y 좌표를 제한된 범위 내로 조정
          const newX = Math.max(minX, Math.min(maxX, pos.x));
          const newY = Math.max(minY, Math.min(maxY, pos.y));

          return {
            x: newX,
            y: newY,
          };
        },
      },

      // 큐볼, 목표볼, 상대볼의 위치
      cueBallPosition: {
        x: 450,
        y: 212.5,
      },
      cueBallPositionVertical: {
        x: 212.5,
        y: 450,
      },
      targetBallPosition: {
        x: 450,
        y: 250,
      },
      targetBallPositionVertical: {
        x: 250,
        y: 450,
      },
      opponentBallPosition: {
        x: 493.75,
        y: 250,
      },
      opponentBallPositionVertical: {
        x: 250,
        y: 493.75,
      },

      // 변수
      ballType: "cue",
      lines: [],
      linesVertical: [],
      selectedPoints: [],
      selectedPotinsVertical: [],
      isLastPointRemoved: false,
      clickedCushionPosition: [],
      hiddenCuball: true,
      hiddenTargetBall: true,
      hiddenOpponentBall: true,
      previousPoint: 0,
      previousPointVertical: 0,
      lastPoint: 0,
      lastPointVertical: 0,

      isCompactView: false,
      isImageCaptured: true,
      previousScrollPosition: 0,
    };
  },

  methods: {
    handleTableClick() {
      const stage = this.$refs.stage.getStage();
      const pointerPosition = stage.getPointerPosition();

      if (this.isCompactView == false && this.isLastPointRemoved == true) {
        this.selectedPoints.push(this.lastPoint);
        this.isLastPointRemoved = false;
      }

      if (this.isCompactView == true && this.isLastPointRemoved == true) {
        this.selectedPotinsVertical.push(this.lastPointVertical);
        this.isLastPointRemoved = false;
      }

      if (this.isCompactView == false) {
        console.log("두번째");
        this.selectedPoints.push(pointerPosition);
        if (this.selectedPoints.length === 2) {
          this.lines.push({
            points: [
              this.selectedPoints[0].x,
              this.selectedPoints[0].y,
              this.selectedPoints[1].x,
              this.selectedPoints[1].y,
            ],
            stroke: "white",
            strokeWidth: 1.5,
          });
          // this.previousPoint = this.selectedPoints[0];

          this.previousPoint = this.selectedPoints.shift();
        }

        // this.clickedCushionPosition.shift();

        // 배열 초기화 - 세로 방향 배열 이전 내용 그대로 누적되므로
        this.linesVertical = [];

        for (let i = 0; i < this.lines.length; i++) {
          const item = this.lines[i];
          let newPoints = [...item.points];

          // 반시계 방향 회전
          [newPoints[0], newPoints[1]] = [
            newPoints[1],
            Math.abs(newPoints[0] - this.stageConfig.width),
          ];
          // 반시게 방향 회전
          [newPoints[2], newPoints[3]] = [
            newPoints[3],
            Math.abs(newPoints[2] - this.stageConfig.width),
          ];

          this.previousPointVertical = {
            x: newPoints[0],
            y: newPoints[1],
          };
          this.selectedPotinsVertical[0] = {
            x: newPoints[2],
            y: newPoints[3],
          };

          // 반시계 방향 회전
          this.linesVertical.push({
            points: newPoints,
            stroke: "white",
            strokeWidth: 1.5,
          });
        }
      } else {
        // 세로 방향 클릭한 좌표
        this.selectedPotinsVertical.push(pointerPosition);

        // 세로 방향 클릭한 좌표
        if (this.selectedPotinsVertical.length === 2) {
          this.linesVertical.push({
            points: [
              this.selectedPotinsVertical[0].x,
              this.selectedPotinsVertical[0].y,
              this.selectedPotinsVertical[1].x,
              this.selectedPotinsVertical[1].y,
            ],
            stroke: "white",
            strokeWidth: 1.5,
          });
          // this.previousPointVertical = this.selectedPotinsVertical[0];

          this.previousPointVertical = this.selectedPotinsVertical.shift();
        }

        // 배열 초기화 - 가로 방향 배열 이전 내용 그대로 누적되므로
        this.lines = [];
        for (let i = 0; i < this.linesVertical.length; i++) {
          const item = this.linesVertical[i];
          let newPoints = [...item.points];

          [newPoints[0], newPoints[1]] = [
            Math.abs(newPoints[1] - this.stageConfigVertical.height),
            newPoints[0],
          ];
          [newPoints[2], newPoints[3]] = [
            Math.abs(newPoints[3] - this.stageConfigVertical.height),
            newPoints[2],
          ];

          this.previousPoint = {
            x: newPoints[0],
            y: newPoints[1],
          };
          this.selectedPoints[0] = {
            x: newPoints[2],
            y: newPoints[3],
          };

          // 가로 방향 클릭한 좌표
          this.lines.push({
            points: newPoints,
            stroke: "white",
            strokeWidth: 1.5,
          });
        }
      }

      // 상태 업데이트를 위해 Vue가 다시 렌더링하도록 강제
      this.$forceUpdate();
    },

    handleDragMove(ballType, e) {
      this.ballType = ballType;

      const target = e.target;
      const { x, y } = target.position();
      // const tableConfigInner = this.tableConfigInner;

      // 반지름: 13(지름 26)
      // console.log("반지름: ", target.radius());

      // 충돌 감지
      const isOverlapping = this.isBallsOverlapping(target, ballType);

      if (isOverlapping) {
        // 겹치면 원래 위치로 되돌리기
        switch (ballType) {
          case "cue":
            target.position(this.cueBallPosition);
            break;
          case "target":
            target.position(this.targetBallPosition);
            break;
          case "opponent":
            target.position(this.opponentBallPosition);
            break;
        }
      } else {
        // 겹치지 않으면 현재 위치 업데이트
        switch (ballType) {
          case "cue":
            if (this.isCompactView == false) {
              this.cueBallPosition = { x, y };

              this.cueBallConfig = {
                ...this.cueBallConfig,
                x: x,
                y: y,
              };

              // 시계 방향 회전
              /* this.cueBallConfigVertical = {
                ...this.cueBallConfigVertical,
                x: Math.abs(y - this.stageConfigVertical.width),
                y: x,
              }; */
              // 반시계 방향 회전
              this.cueBallConfigVertical = {
                ...this.cueBallConfigVertical,
                x: y,
                y: Math.abs(x - this.stageConfig.width),
              };
            } else {
              this.cueBallPositionVertical = { x, y };

              this.cueBallConfigVertical = {
                ...this.cueBallConfigVertical,
                x: x,
                y: y,
              };

              this.cueBallConfig = {
                ...this.cueBallConfig,
                x: Math.abs(y - this.stageConfigVertical.height),
                y: x,
              };
            }
            break;
          case "target":
            if (this.isCompactView == false) {
              this.targetBallPosition = { x, y };

              this.targetBallConfig = {
                ...this.targetBallConfig,
                x: x,
                y: y,
              };
              // 시계 방향 회전
              /* this.targetBallConfigVertical = {
                ...this.targetBallConfigVertical,
                x: Math.abs(y - this.stageConfigVertical.width),
                y: x,
              }; */
              // 반시계 방향 회전
              this.targetBallConfigVertical = {
                ...this.targetBallConfigVertical,
                x: y,
                y: Math.abs(x - this.stageConfig.width),
              };
            } else {
              this.targetBallPositionVertical = { x, y };

              this.targetBallConfigVertical = {
                ...this.targetBallConfigVertical,
                x: x,
                y: y,
              };

              this.targetBallConfig = {
                ...this.targetBallConfig,
                x: Math.abs(y - this.stageConfigVertical.height),
                y: x,
              };
            }
            break;
          case "opponent":
            // this.opponentBallPosition = { x, y };
            if (this.isCompactView == false) {
              this.opponentBallPosition = { x, y };

              this.opponentBallConfig = {
                ...this.opponentBallConfig,
                x: x,
                y: y,
              };
              // 시계 방향 회전
              /* this.opponentBallConfigVertical = {
                ...this.opponentBallConfigVertical,
                x: Math.abs(y - this.stageConfigVertical.width),
                y: x,
              }; */
              // 반시계 방향 회전
              this.opponentBallConfigVertical = {
                ...this.opponentBallConfigVertical,
                x: y,
                y: Math.abs(x - this.stageConfig.width),
              };
            } else {
              this.opponentBallPositionVertical = { x, y };

              this.opponentBallConfigVertical = {
                ...this.opponentBallConfigVertical,
                x: x,
                y: y,
              };

              this.opponentBallConfig = {
                ...this.opponentBallConfig,
                x: Math.abs(y - this.stageConfigVertical.height),
                y: x,
              };
            }
            break;
        }
      }

      this.clickedCushionPosition.shift();
    },
    handleDragEnd(/* ballType, e */) {
      /* const target = e.target;

      console.log(target); */
    },

    isBallsOverlapping(target, ballType) {
      const currentPosition = target.position();
      const radius = target.radius();

      // 다른 공과의 충돌 여부 확인
      const balls = [
        { type: "cue", position: this.cueBallPosition },
        { type: "target", position: this.targetBallPosition },
        { type: "opponent", position: this.opponentBallPosition },
      ];

      for (const ball of balls) {
        // 자기 자신은 비교하지 않음
        if (ball.type === ballType) continue;

        // x의 변화량, 현재 드래그 중인 공과 다른 공 간의 x 좌표의 차이
        const dx = currentPosition.x - ball.position.x;
        const dy = currentPosition.y - ball.position.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        // 두 공이 겹치는지 확인
        if (distance < radius * 2) {
          // console.log("겹칩");
          return true; // 겹침
        }
      }

      return false; // 겹치지 않음
    },
    handleBallClickVisibility(ballType) {
      switch (ballType) {
        case "cue":
          if (this.hiddenCuball) {
            this.hiddenCuball = false;
          } else {
            this.hiddenCuball = true;
          }
          break;
        case "target":
          if (this.hiddenTargetBall) {
            this.hiddenTargetBall = false;
          } else {
            this.hiddenTargetBall = true;
          }
          break;
        case "opponent":
          if (this.hiddenOpponentBall) {
            this.hiddenOpponentBall = false;
          } else {
            this.hiddenOpponentBall = true;
          }
          break;
      }
    },
    getClosestCushionEdgePoint(x, y) {
      const cushion = this.cushionConfig;
      const innerCushionWidth = 20.4;

      // 쿠션의 안쪽 끝부분 좌표 계산
      // cushion.x - 쿠션의 왼쪽 상단 모서리의 X 좌표
      // x, y는 매개변수로 전달 받은 값
      const cushionEdgePoints = [
        { x: cushion.x + innerCushionWidth, y }, // 왼쪽 안쪽 끝
        { x: cushion.x + cushion.width - innerCushionWidth, y }, // 오른쪽 안쪽 끝
        { x, y: cushion.y + innerCushionWidth }, // 위쪽 안쪽 끝
        { x, y: cushion.y + cushion.height - innerCushionWidth }, // 아래쪽 안쪽 끝
      ];

      // 클릭한 좌표에서 가장 가까운 안쪽 끝 좌표 찾기
      let closestCushionEdgePoint = cushionEdgePoints[0];

      // 클릭한 지점과 왼쪽 쿠션에서 클릭한 테두리지점 간의 거리(이걸 최소거리로 우선 잡는다)
      let minDistance = this.getDistance(
        x,
        y,
        closestCushionEdgePoint.x,
        closestCushionEdgePoint.y
      );

      cushionEdgePoints.forEach((point) => {
        // 클릭한 지점과 각 쿠션에서의 테두리지점 사이의 거리 계산
        const distance = this.getDistance(x, y, point.x, point.y);
        // 비교하여 클릭한 지점과 가장 가까운 거리를 갖는 포인트(좌표)를 반환
        if (distance < minDistance) {
          minDistance = distance;
          closestCushionEdgePoint = point;
        }
      });

      return closestCushionEdgePoint;
    },
    getDistance(x1, y1, x2, y2) {
      return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    },

    removeLastLineAndClickedPoint() {
      if (this.isCompactView == false) {
        if (this.selectedPoints.length) {
          console.log("가로 if");
          this.selectedPoints.pop();
          this.selectedPotinsVertical.pop();
          this.isLastPointRemoved = true;

          let lastPoint = this.lines[this.lines.length - 1];

          this.lastPoint = { x: lastPoint.points[2], y: lastPoint.points[3] };
        } else {
          console.log("가로 else");
          this.lines.pop();
          this.linesVertical.pop();

          if (this.lines.length == 0) {
            this.isLastPointRemoved = false;
            this.lastPoint = 0;
            console.log(this.lastPoint);
            return;
          }

          let lastPoint = this.lines[this.lines.length - 1];

          this.lastPoint = { x: lastPoint.points[2], y: lastPoint.points[3] };
          this.lastPointVertical = {
            x: lastPoint.points[3],
            y: Math.abs(lastPoint.points[2] - this.stageConfig.width),
          };
        }
      } else {
        if (this.selectedPotinsVertical.length) {
          console.log("세로 if");
          this.selectedPotinsVertical.pop();
          this.selectedPoints.pop();
          this.isLastPointRemoved = true;

          let lastPointVertical =
            this.linesVertical[this.linesVertical.length - 1];
          this.lastPointVertical = {
            x: lastPointVertical.points[2],
            y: lastPointVertical.points[3],
          };
        } else {
          console.log("세로 else");
          this.linesVertical.pop();
          this.lines.pop();

          if (this.linesVertical.length == 0) {
            this.isLastPointRemoved = false;
            this.lastPointVertical = 0;
            return;
          }

          let lastPointVertical =
            this.linesVertical[this.linesVertical.length - 1];

          this.lastPointVertical = {
            x: lastPointVertical.points[2],
            y: lastPointVertical.points[3],
          };

          this.lastPoint = {
            x: Math.abs(
              lastPointVertical.points[3] - this.stageConfigVertical.height
            ),
            y: lastPointVertical.points[2],
          };
        }
      }
    },

    /* removeClickedPoint() {
      this.selectedPoints.pop();
    }, */

    calculateRotationAngle(previousPoint, currentPoint) {
      const deltaX = previousPoint.x - currentPoint.x;
      const deltaY = previousPoint.y - currentPoint.y;

      // 직각 삼각형으로 확인해보기
      /* const deltaX = currentPoint.x - previousPoint.x;
      const deltaY = currentPoint.y - previousPoint.y; */
      // Math.atan2(deltaY, deltaX): 라디안, Math.PI: 원주율
      const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // 라디안 -> 각도로 변환
      // angle은 둔각이 구해진다 여기서 90도를 빼야 한다
      // 기존 하늘 방향의 꼭짓점이 좌측아래 꼭지점으로 위치해야 하므로
      //console.log(angle);
      return angle - 90;
    },

    checkScreenSize() {
      // window.innerWidth가 900이하 일때 세로 방향으로 테이블 위치 바뀌게
      if (window.innerWidth >= 995 && this.isCompactView) {
        this.isCompactView = false;
      }
      if (window.innerWidth <= 995 && !this.isCompactView) {
        this.isCompactView = true;
      }
    },

    async saveCanvasAsImage() {
      /* this.$refs.table.getStage().batchDraw();

      console.log("가로", this.lastPoint);
      console.log("세로", this.lastPointVertical);
      console.log("너비", this.$refs.table.getStage().width());
      console.log("높이", this.$refs.table.getStage().height()); */

      if (this.isCompactView == true) {
        this.previousScrollPosition = window.scrollY;
        this.isImageCaptured = false;
        this.isCompactView = false;
        await new Promise((resolve) => setTimeout(resolve, 0.001));
      }

      return this.$refs.table.getStage().toDataURL({
        pixelRatio: 1, // 50% 크기로 줄이기
        quality: 1,
        /* pixelRatio: 0.5, // 50% 크기로 줄이기
        quality: 0.8, */
      });
    },

    async onSubmit() {
      this.form.table_image = await this.saveCanvasAsImage();

      if (this.isImageCaptured == false) {
        this.isCompactView = true;

        // 이전 스크롤 위치 유지
        this.$nextTick(() => {
          window.scrollTo({
            top: this.previousScrollPosition,
            left: 0,
            behavior: "instant",
          });
        });

        // this.isImageCaptured = true;
      }

      this.form.ballConfig = [
        this.cueBallConfig,
        this.targetBallConfig,
        this.opponentBallConfig,
      ];

      this.form.ballConfigVertical = [
        this.cueBallConfigVertical,
        this.targetBallConfigVertical,
        this.opponentBallConfigVertical,
      ];

      this.form.lines = this.lines;

      this.form.linesVertical = this.linesVertical;

      try {
        if (this.$route.params.id) {
          await axiosInstance.put(`/posts/${this.$route.params.id}`, this.form);
        } else {
          await axiosInstance.post("/posts", this.form);
        }

        // console.log(response);

        this.$router.push("/");
      } catch (error) {
        if (error.response && error.status == 401) {
          this.error = `${error.response.data.message}`;
        } else {
          this.error = "서버와의 연결에 문제가 발생했습니다.";
        }
      }

      this.$router.push("/");
    },

    async fetchData() {
      if (this.$route.params.id) {
        const response = await axiosInstance.get(
          `/posts/${this.$route.params.id}`
        );

        // this.$emit("send-data", response.data);

        this.lines = response.data.lines;
        this.linesVertical = response.data.lines_vertical;

        this.cueBallConfig = response.data.ball_config[0];
        this.targetBallConfig = response.data.ball_config[1];
        this.opponentBallConfig = response.data.ball_config[2];

        this.cueBallConfigVertical = response.data.ball_config_vertical[0];
        this.targetBallConfigVertical = response.data.ball_config_vertical[1];
        this.opponentBallConfigVertical = response.data.ball_config_vertical[2];

        this.previousPoint = {
          x: response.data.lines[response.data.lines.length - 1].points[0],
          y: response.data.lines[response.data.lines.length - 1].points[1],
        };

        this.selectedPoints[0] = {
          x: response.data.lines[response.data.lines.length - 1].points[2],
          y: response.data.lines[response.data.lines.length - 1].points[3],
        };

        this.previousPointVertical = {
          x: response.data.lines_vertical[
            response.data.lines_vertical.length - 1
          ].points[0],
          y: response.data.lines_vertical[
            response.data.lines_vertical.length - 1
          ].points[1],
        };

        this.selectedPotinsVertical[0] = {
          x: response.data.lines_vertical[
            response.data.lines_vertical.length - 1
          ].points[2],
          y: response.data.lines_vertical[
            response.data.lines_vertical.length - 1
          ].points[3],
        };

        this.form.title = response.data.title;
        this.form.content = response.data.content;

        return response;
      } else {
        /* const response = await axiosInstance.get("/posts");

        return response; */
      }
    },
  },

  created() {
    this.fetchData();
  },

  mounted() {
    // 컴포넌트가 마운트되면 초기 화면 크기 체크
    this.checkScreenSize();

    // `resize` 이벤트 리스너 (화면 크기 변경 감지)
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped></style>
