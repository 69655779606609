<template>
  <div>
    <BilliardTable />
  </div>
</template>

<script>
import BilliardTable from "@/components/BilliardTable.vue";

export default {
  components: {
    BilliardTable,
  },
};
</script>

<style scoped></style>
