import { createRouter, createWebHistory } from "vue-router";

// 페이지 컴포넌트 임포트
import Home from "@/pages/Home.vue";

import Login from "@/pages/user/Login.vue";
import Register from "@/pages/user/Register.vue";

import PostCreate from "@/pages/PostCreate.vue";
import PostDetail from "@/pages/PostDetail.vue";
import PostEdit from "@/pages/PostEdit.vue";

import axiosInstance from "@/api/axiosInstance";

// 라우트 정의
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/post/user",
    name: "post-user",
    component: Home,
    beforeEnter: (to, from, next) => {
      const accessToken = localStorage.getItem("access_token");

      // console.log("출력2");

      if (!accessToken) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },

  {
    path: "/post/:id",
    name: "post-detail",
    component: PostDetail,
  },

  {
    path: "/post/create",
    name: "post-create",
    component: PostCreate,
    beforeEnter: async (to, from, next) => {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        next({ name: "Login" });
      } else {
        try {
          await axiosInstance.get("/posts/user");

          next();
        } catch (error) {
          if (error.response && error.response.status === 401) {
            next({ name: "Login" });
          } else {
            next(false);
          }
        }
      }
    },
  },

  {
    path: "/post/edit/:id",
    name: "post-edit",
    component: PostEdit,
    beforeEnter: (to, from, next) => {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
];

const router = createRouter({
  history: createWebHistory(/* process.env.BASE_URL */), // HTML5 History 모드
  routes,
});

export default router;
