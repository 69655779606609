import axios from "axios";
import router from "@/router";
import store from "@/store";

// 모든 axios 요청시 요청 인터셉터, 응답 인터셉터가 자동으로 실행된다.
// axiosInstance를 사용하여 보내는 모든 요청은 설정한 요청 및 응답 인터셉터를 통해 처리된다

// Axios 인스턴스 생성
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 10000, // 10초 내에 완료되지 않으면 에러 발생
  headers: {
    "Content-Type": "application/json", // 기본 Content-Type 헤더
  },
});

// 요청 인터셉터 설정
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Bearer 토큰 추가
    }
    // console.log("토큰 확인");
    return config; // 요청을 계속 진행
  },
  (error) => {
    return Promise.reject(error); // 요청 오류 처리
  }
);

// 응답 인터셉터 설정
axiosInstance.interceptors.response.use(
  (response) => {
    return response; // 성공적으로 응답받았을 때
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("access_token"); // 토큰 제거
      // 사용자에게 로그인 페이지로 리다이렉트 (예: Vue Router 사용)
      // console.log("응답 인터셉터");

      store.commit("setUser", "logout");

      router.push("/login");
      // console.error("Unauthorized access - Redirecting to login.");
    }
    return Promise.reject(error); // 오류 처리
  }
);

export default axiosInstance;
