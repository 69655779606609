const state = {
  user: "",
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

export default {
  state,
  mutations,
  getters,
};
