<template>
  <div class="container mt-5">
    <div class="row justify-content-center m-0">
      <b-card style="max-width: 480px">
        <h2 class="text-center">회원가입</h2>
        <b-form @submit.prevent="onSubmit">
          <b-form-group label="아이디" label-for="username">
            <b-form-input
              id="username"
              v-model="form.username"
              type="text"
              required
              placeholder="아이디를 입력하세요"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="비밀번호" label-for="password">
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              required
              placeholder="비밀번호를 입력하세요"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="비밀번호 확인" label-for="confirm_password">
            <b-form-input
              id="confirm_password"
              v-model="form.confirm_password"
              type="password"
              required
              placeholder="비밀번호를 다시 입력하세요"
            ></b-form-input>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button variant="link" @click="goToLogin">로그인</b-button>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <b-button type="submit" variant="primary" block>회원가입</b-button>
          </div>

          <div
            v-if="error"
            class="alert alert-danger text-center mt-3"
            role="alert"
            style="font-size: 14px"
          >
            {{ error }}
          </div>
        </b-form>

        <!-- <b-alert v-if="error" variant="danger" dismissible>
          {{ error }}
        </b-alert> -->
      </b-card>

      <!-- <div v-if="successMessage" class="alert alert-success mt-3" role="alert">
        {{ successMessage }}
      </div> -->
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  name: "Register",

  data() {
    return {
      form: {
        username: "",
        password: "",
        confirm_password: "",
      },
      successMessage: null,
      error: null,
    };
  },
  methods: {
    async onSubmit() {
      this.error = null;

      // 비밀번호 일치 확인
      if (this.form.password !== this.form.confirm_password) {
        this.error = "비밀번호가 일치하지 않습니다.";
        return;
      }

      try {
        // 서버로 회원가입 요청 (예시용 URL, 실제 API 경로로 변경)
        await axiosInstance.post("/users/register", {
          username: this.form.username,
          password: this.form.password,
        });

        // 성공 시 추가 동작
        // console.log("회원가입 성공:", response.data);

        // 회원가입 성공 메시지
        // this.successMessage = "회원가입이 완료되었습니다!";
        alert("회원가입이 완료되었습니다!");

        // 성공 후 로직
        this.$router.push("/login");
      } catch (error) {
        if (error.response && error.status == 409) {
          this.error = `${error.response.data.message}`;
          //console.log(this.error);
        } else if (error.response && error.status == 400) {
          this.error = `${error.response.data.message}`;
        } else {
          this.error = "서버와의 연결에 문제가 발생했습니다.";
        }
      }
    },

    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
