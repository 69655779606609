<template>
  <div>
    <BilliardTableDetail @send-data="handleData" />

    <hr style="margin-bottom: 30px" />

    <div style="padding: 0 55px">
      <!-- 게시물 제목 -->
      <h2 class="h1 mb-3 font-weight-bold">{{ post.title }}</h2>

      <!-- 게시물 내용 -->
      <p class="lead lh-lg mb-5">{{ post.content }}</p>

      <div class="my-5" v-if="isPostedUser">
        <b-button type="submit" variant="primary" class="ms-2" @click="goToEdit"
          >수정</b-button
        >
        <b-button
          type="submit"
          variant="danger"
          class="ms-2"
          @click="deletePost"
          >삭제</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import BilliardTableDetail from "@/components/BilliardTableDetail.vue";
import axiosInstance from "@/api/axiosInstance";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    BilliardTableDetail,
  },
  data() {
    return {
      post: {
        imageUrl: "",
        title: "",
        content: "",
      },

      isPostedUser: false,
    };
  },

  created() {
    // const postId = this.$route.params.id;
    // this.fetchPost(postId);
    this.getCurrentUserId();
  },

  methods: {
    async handleData(data) {
      this.post.title = data.title;
      this.post.content = data.content;

      this.isPostedUser = this.currentUserId == data.user.username;
    },

    getCurrentUserId() {
      const token = localStorage.getItem("access_token");
      if (token) {
        const decoded = jwtDecode(token);
        this.currentUserId = decoded.username;
      }
    },

    deletePost() {
      axiosInstance.delete(`/posts/${this.$route.params.id}`);

      // console.log("delete", response);

      this.$router.push("/");
    },

    goToEdit() {
      this.$router.push(`/post/edit/${this.$route.params.id}`);
    },

    async fetchPost(/* id */) {
      /* try {
        const response = await axiosInstance.get(`/posts/${id}`);

        this.post.title = response.data.title;
        this.post.content = response.data.content;
      } catch (error) {
        console.error("Error fetching data:", error);
      } */
    },
  },
};
</script>

<style scoped></style>
