<template>
  <div>
    <b-container
      class="mt-4 mb-4"
      style="display: flex; justify-content: center"
    >
      <div>
        <h4 class="mb-3 fw-bold">경로 작성</h4>

        <BilliardTable />
      </div>
    </b-container>
  </div>
</template>

<script>
import BilliardTable from "@/components/BilliardTable.vue";

export default {
  components: {
    BilliardTable,
  },
};
</script>

<style scoped></style>
